import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StringParam, useQueryParams } from 'use-query-params';

import { calculateView } from 'components/common/klueCard/hooks/utils';
import { useCard } from 'hooks/use-card';

import type { Dispatch } from 'store/store.types';

const useCardEditorModalContainer = () => {
  const [{ editCard: currentCardId }, setQuery] = useQueryParams({
    editCard: StringParam,
    laneId: StringParam,
    viewOrder: StringParam,
  });
  const view = calculateView();
  const dispatch = useDispatch<Dispatch>();

  const { card, profile, loadCard, failed } = useCard(
    currentCardId ? String(currentCardId) : undefined,
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!card && currentCardId && currentCardId !== 'new') {
      setIsLoading(true);
      loadCard(+currentCardId);
    }
  }, [loadCard, card, currentCardId]);

  useEffect(() => {
    if (card || failed) {
      setIsLoading(false);
    }
  }, [card, failed]);

  const close = useCallback(
    (didSave?: boolean) => {
      setQuery({
        editCard: undefined,
        laneId: undefined,
        viewOrder: undefined,
      });

      if (
        view === 'search' &&
        didSave &&
        currentCardId &&
        currentCardId !== 'new'
      ) {
        dispatch.search.updateResultCard(+currentCardId);
      }
    },
    [setQuery, view, currentCardId, dispatch],
  );

  return {
    currentCardId,
    targetCard: card,
    isNewCard: currentCardId === 'new',
    profile,
    isLoading: isLoading && !failed && !card,
    failed,
    close,
  };
};

export default useCardEditorModalContainer;
