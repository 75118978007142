import {
  Box,
  Text,
  Anchor,
  Button,
  Image,
  tokens,
  PhosphorIcon,
} from '@kluein/klue-ui';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useAuth } from 'contexts/auth';
import { APP_V1_BASEURL } from 'lib/urls';
import { formatName } from 'lib/utils';
import { getMostRecentCardActionDate } from 'pages/profile/views/battlecards/utils';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import type { KlueCardInfoPropsType } from './KlueCardInfo.types';
import type { UserType } from 'api/api.types';
import type { Dispatch, RootState } from 'store/store.types';

const IconButton = styled(Button)`
  height: 20px;
  display: flex;
  align-items: center;
`;

const KlueCardInfo = ({
  card,
  sourcesCount,
  hasCardSourcesIcon = true,
  onCardSourcesClick,
  shouldOpenInNewTab = false,
}: KlueCardInfoPropsType) => {
  const { t } = useTranslation();
  const { isCardViewerUpdateEnabled } = useAuth();
  const dispatch = useDispatch<Dispatch>();
  const actionDate = getMostRecentCardActionDate(card);
  const userAuthor = useSelector<RootState, UserType | undefined>((state) =>
    state.users.byId.get(String(actionDate.author)),
  );

  const userName =
    actionDate.author && userAuthor ? userAuthor.name : t('Common:you');
  const updateDateFormated = format(
    actionDate.date,
    'LLL dd, yyyy',
  ).toLocaleUpperCase();

  const hasCardSources = !!sourcesCount;
  const shouldShowCardSourcesIcon =
    hasCardSourcesIcon && hasCardSources && !isCardViewerUpdateEnabled;

  const sourcesTooltipContent = hasCardSources
    ? t('Card:sources.showSourcesTooltip', { count: sourcesCount })
    : t('Card:sources.addSources');

  useEffect(() => {
    if (actionDate.author) {
      dispatch.users.loadOrFetchUserById(actionDate.author);
    }
  }, [dispatch, actionDate.author]);

  return (
    <Box
      direction="row"
      align="center"
      margin="none"
      justify="between"
      data-test-id={TEST_IDS.klueCard.klueCardInfo.container}
    >
      {!!actionDate.author && (
        <Box direction="row" align="center" gap="small">
          <Box flex={false}>
            <Text
              as="time"
              size="small"
              color={tokens.color.neutral.black.main}
              className="chromatic-ignore"
              fontWeight="semibold"
            >
              {updateDateFormated}
            </Text>
          </Box>
          <Box
            width="hair"
            height="xxsmall"
            background={tokens.color.neutral.mediumgrey.main}
          />
          {userAuthor?.image && (
            <Box
              border={{
                color: tokens.color.neutral.black.main,
                side: 'all',
                size: 'small',
              }}
              overflow="hidden"
              round="full"
              width="xxsmall"
              height="xxsmall"
              flex={false}
            >
              <Image alt={userName} src={userAuthor?.image} fit="contain" />
            </Box>
          )}
          <Text
            size="small"
            color={tokens.color.neutral.black.main}
            fontWeight="semibold"
            wordBreak="break-all"
          >
            <Anchor
              href={`${APP_V1_BASEURL}/users/${userAuthor?.id}`}
              label={formatName(userName || '')}
              data-tracking-id={TRACKING_IDS.global.card.author}
              external={shouldOpenInNewTab}
              hideExternalIcon
            />
          </Text>
        </Box>
      )}
      {!!shouldShowCardSourcesIcon && (
        <Box align="end">
          <IconButton
            variant="plain"
            onClick={onCardSourcesClick}
            title={t('Card:sources.title')}
            size="small"
            tip={{
              content: sourcesTooltipContent.toUpperCase(),
              dropProps: { align: { bottom: 'top' } },
            }}
            data-test-id={TEST_IDS.klueCard.klueCardInfo.cardSourceIcon}
            icon={
              <PhosphorIcon.Info
                color={tokens.color.neutral.black.main}
                size={tokens.iconSize.small}
              />
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default KlueCardInfo;
