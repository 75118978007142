const getBaseUrl = () => window.location.origin;

export const APP_BASEURL = getBaseUrl();
export const API_BASEURL = `${APP_BASEURL}/api`;
export const API_TRAYCLIENT_BASEURL = `${APP_BASEURL}/trayclient`;
export const API_PERPLEXITY_BASEURL = 'https://api.perplexity.ai';
export const APP_BASEURL_HOSTNAME = new URL(APP_BASEURL).hostname;
export const APP_V1_BASEURL = String(
  import.meta.env.VITE_KLUE_V1_BASEURL ||
    import.meta.env.STORYBOOK_APP_KLUE_V1_BASEURL,
);
export const APP_V1_BASEURL_HOSTNAME = new URL(APP_V1_BASEURL).hostname;

export const MODE_BASE_URL = String(
  import.meta.env.VITE_MODE_BASE_URL || 'https://app.mode.com',
);

export const MODE_SANDBOX_URL = String(
  import.meta.env.VITE_MODE_SANDBOX_URL || 'https://modesandbox.com',
);

export const APP_HELP_URL = String(
  import.meta.env.VITE_HELP_URL || 'https://help.app.klue.com',
);
export const WHATS_NEW_URL = String(
  import.meta.env.VITE_WHATS_NEW_URL || 'https://help.app.klue.com/what-s-new',
);
export const SLACK_HELP_URL = {
  consumer: import.meta.env.VITE_INTEGRATION_SLACK_HELP_URL_CONSUMER || '',
  curator: import.meta.env.VITE_INTEGRATION_SLACK_HELP_URL_CURATOR || '',
};
export const SLACK_LINK_URL =
  import.meta.env.VITE_INTEGRATION_SLACK_INSTALL_URL || '';
export const KLUE_EMAIL_HELP_URL = {
  consumer: import.meta.env.VITE_INTEGRATION_KLUE_EMAIL_HELP_URL_CONSUMER || '',
  curator: import.meta.env.VITE_INTEGRATION_KLUE_EMAIL_HELP_URL_CURATOR || '',
};
export const KLUE_BUTTON_HELP_URL = {
  consumer:
    import.meta.env.VITE_INTEGRATION_KLUE_BUTTON_HELP_URL_CONSUMER || '',
  curator: import.meta.env.VITE_INTEGRATION_KLUE_BUTTON_HELP_URL_CURATOR || '',
};
export const KLUE_MOBILE_APP_HELP_URL = {
  consumer: import.meta.env.VITE_INTEGRATION_MOBILE_APP_HELP_URL_CONSUMER || '',
  curator: import.meta.env.VITE_INTEGRATION_MOBILE_APP_HELP_URL_CURATOR || '',
};
export const SALESFORCE_HELP_URL = {
  consumer: import.meta.env.VITE_INTEGRATION_SALESFORCE_HELP_URL_CONSUMER || '',
  curator: import.meta.env.VITE_INTEGRATION_SALESFORCE_HELP_URL_CURATOR || '',
};
export const TEAMS_HELP_URL =
  import.meta.env.VITE_INTEGRATION_MS_TEAMS_HELP_URL || '';

export const IOS_APP_URL = import.meta.env.VITE_INTEGRATION_IOS_APP_URL || '';
export const ANDROID_APP_URL =
  import.meta.env.VITE_INTEGRATION_ANDROID_APP_URL || '';
export const CHROME_EXTENSION_URL =
  import.meta.env.VITE_INTEGRATION_CHROME_EXTENSION_URL || '';
export const EDGE_EXTENSION_URL =
  import.meta.env.VITE_INTEGRATION_EDGE_EXTENSION_URL || '';
export const TEAMS_APP_URL =
  import.meta.env.VITE_INTEGRATIONS_MS_TEAMS_URL || '';

export const CONFIGURE_CURATOR_ASSIGNMENTS_URL =
  import.meta.env.VITE_CONFIGURE_CURATOR_ASSIGNMENTS_URL ||
  'https://help.app.klue.com/article/lk2jc7y5hp-assign-curators-to-a-board';

export const DATA_EXPORT_HELP_URL =
  import.meta.env.VITE_INTEGRATION_DATA_EXPORT_HELP_URL || '';

export const CONTENT_EXPORT_HELP_URL =
  import.meta.env.VITE_CONTENT_EXPORT_HELP_URL ||
  'https://help.app.klue.com/article/zv5zha3hbx';

export const UNLEASH_URL = String(
  import.meta.env.VITE_UNLEASH_URL ||
    'https://unleash.app-staging.klue.io/api/frontend',
);
