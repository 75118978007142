import React from 'react';

import type { AuthContextType, CompanyLinkDefaultType } from './auth.types';

const initialValue = {
  loading: true,
  user: null,
  company: null,
  signOut: () => null,
  updateUser: () => null,
  fetchUser: () => null,
  companyLinkDefault: 'profile' as CompanyLinkDefaultType,
  homeRoute: '/dashboard',
  showCardInfo: true,
  isReportsEnabled: false,
  isTagVisible: false,
  isCurator: false,
  isAdmin: false,
  isStaff: false,
  isDigestAvailable: false,
  isImpersonating: false,
  revertImpersonatingUser: () => null,
  isCardViewerUpdateEnabled: false,
  isDashboardNewsEnabled: false,
  isCardFeedbackDisabled: true,
  isCardSentimentEnabled: false,
  isCardSentimentAndFeedbackOnHoverDisabled: false,
  isAlertsRecommendedTagEnabled: false,
  isAlertsAutoSummarizeOnTriageEnabled: true,
  isWinLossEnabled: false,
  isWinLossExclusive: false,
  isBattlecardLayoutLabelDisabled: false,
  isCompetitiveAnalyticsOverviewReportEnabled: false,
  isCompetitiveAnalyticsThreatAnalysisReportEnabled: false,
  isCompetitiveAnalyticsRepPerformanceReportEnabled: false,
  isCompetitiveAnalyticsDataHygieneReportEnabled: false,
  isCompetitiveAnalyticsImpactReportEnabled: false,
  isCompetitiveAnalyticsProgramImpactReportEnabled: false,
  isConsumerReportEnabledForCurators: false,
  isCuratorReportEnabledForCurators: false,
  isCRAReportEnabledForCurators: false,
  isAskKlueExportEnabledForCurators: false,
  isAskKlueExportEnabled: false,
  isCompetitiveDashboardEnabledForConsumers: false,
  isAlertsNotificationEnabledForCurators: false,
  isRivalLinkHiddenForConsumers: false,
  isUnreadStateEnabledForConsumers: false,
  isAskKlueEnabled: false,
  isDashboardAskKlueEnabled: false,
  isBattlecardAskKlueEnabled: false,
  isDashboardAskKluePreviewForCuratorsEnabled: false,
  isDashboardNewsToggleDefaultOff: false,
  isCompanyLandingPageEnabled: false,
  isCompanyFavoritesEnabled: false,
  isUpdateSummaryEnabled: false,
  isGAEnabled: false,
  isSFDCDataSyncContactsEnabled: false,
  isSFDCDataSyncUserRoleEnabled: false,
  isReviewInsightsCreateCardEnabled: false,
  isProfileSideNavNewsDisabled: false,
  isAlertsTitleEditingDisabled: false,
  isNewCuratorNavEnabled: false,
  isFeedHiddenInConsumerNavEnabled: false,
  isInsightsWhyWeWinLoseEnabled: false,
  isCallInsightsDemoReplacementEnabled: false,
  isNewCardEditorEnabled: false,
  isEditorTemplateBlocksEnabled: false,
  isNewCardRendererEnabled: false,
  isV2CuratorDashboardEnabled: false,
  isGenAISearchResultsEnabled: false,
  isSFDSCustomLookupObjectEnabled: false,
  isSFDSCustomJunctionObjectEnabled: false,
  isHSDSEnabled: false,
  isHSDSAuthEnabled: false,
  isAskKlueWizardEnabled: false,
  isAskKlueSingleClickPromptEnabled: false,
  isConsumerAskKlueToggleHidden: false,
  isKnowledgeHubEnabled: false,
  isCompetitiveDashboardVisible: true,
  isDynamicBoardsEnabled: false,
};

const authContext = React.createContext<AuthContextType>(initialValue);

export default authContext;
