import { getPersistor } from '@rematch/persist';
import React, { type ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Routes,
  useLocation,
  Route,
  Navigate,
  Outlet,
  matchPath,
} from 'react-router-dom';
// Child dependency of @rematch/persist
import { PersistGate } from 'redux-persist/integration/react';
import 'requestidlecallback-polyfill';

import CardEditorModalContainer from 'components/common/card-editor/CardEditorModalContainer';
import { CardViewer } from 'components/common/card-viewer';
import { PageNotAuthorized } from 'components/fallback';
import PageNotFound from 'components/fallback/PageNotFound';
import { MainLayout } from 'components/layout/main-layout';
import { useAuth } from 'contexts/auth';
import { useBulkEdit } from 'contexts/ui/bulk-edit-context';
import { useRedirect } from 'hooks/use-redirect';
import Embeds from 'lib/Embeds';
import ErrorBoundary from 'lib/ErrorBoundary';
import {
  WIN_LOSS_URL_BASE,
  WIN_LOSS_PUBLIC_URL_BASE,
} from 'modules/win-loss/constants';

import type { Dispatch } from 'store/store.types';

// Use Lazy load to split routes into chunks and reduce the bundle load time
const ProfileView = React.lazy(() => import('./pages/profile/Router'));
const ReportsView = React.lazy(() => import('./pages/reports/ReportsView'));
const AlertsRouter = React.lazy(() => import('./pages/alerts/router'));
const SearchView = React.lazy(() => import('./pages/search/SearchView'));
const IntegrationsRouter = React.lazy(
  () => import('./pages/integrations/Router'),
);

const DashboardView = React.lazy(
  () => import('./pages/dashboard/DashboardView'),
);
const ReviewInsightsRouter = React.lazy(
  () => import('./pages/review-insights/router'),
);
const MonitorsRouter = React.lazy(() => import('./pages/monitors/router'));
const CardEmbedsView = React.lazy(
  () => import('./pages/embed-card/CardEmbedsView'),
);

const PublicWinLossRouter = React.lazy(
  () => import('./modules/win-loss/public-router'),
);
const WinLossRouter = React.lazy(() => import('./modules/win-loss/router'));
const WinLossPromoRouter = React.lazy(
  () => import('./modules/win-loss/promo-router'),
);

const SettingsRouter = React.lazy(() => import('./pages/settings/router'));

const CallInsightsRouter = React.lazy(
  () => import('./pages/call-insights/Router'),
);

const ExtensionGuideView = React.lazy(
  () => import('./pages/extension/ExtensionGuideView'),
);

const CompanyRouter = React.lazy(() => import('./pages/company/CompanyRouter'));

const KnowledgeHubRouter = React.lazy(() => import('./pages/knowledge-hub/'));
const DynamicBoardRouter = React.lazy(
  () => import('./pages/dynamic-board/Router'),
);

// For persistent redux state
const persistor = getPersistor();

function RedirectToV1() {
  const { redirectToV1 } = useRedirect();
  redirectToV1();
  return null;
}

/**
 * The application main container
 * @component
 *
 * @visibleName App
 */

function App() {
  const dispatch = useDispatch<Dispatch>();

  const {
    homeRoute,
    isReportsEnabled,
    isCurator,
    isAdmin,
    user,
    isWinLossEnabled,
    isNewCardEditorEnabled,
    isV2CuratorDashboardEnabled,
    isKnowledgeHubEnabled,
  } = useAuth();
  const { isActive: isBulkEditActive } = useBulkEdit();
  const { redirectToV1, redirectToV1Admin } = useRedirect();
  const { pathname } = useLocation();

  useEffect(() => {
    // it helps reducing the priority for this call
    // TODO: Implement a better strategy to fetch rivals
    // without blocking the initial load for most important content
    window.requestIdleCallback(function () {
      dispatch.rivals.fetchAllRivals(null);
    });
  }, [dispatch]);

  if (pathname.startsWith('/admin')) {
    redirectToV1Admin();

    return null;
  }

  // Reports don't exist in v1, render not authorized page instead
  if (pathname.startsWith('/reports') && !isReportsEnabled) {
    return <PageNotAuthorized />;
  }

  if (isCurator) {
    if (
      (pathname === '/' || pathname === '/dashboard') &&
      !isV2CuratorDashboardEnabled
    ) {
      redirectToV1();
      return null;
    }

    if (
      pathname.startsWith('/profile') &&
      !isBulkEditActive &&
      !isNewCardEditorEnabled
    ) {
      redirectToV1();
      return null;
    }
  }

  // Alerts can be accessed only by curators
  if (pathname.startsWith('/alerts') && !isCurator) {
    return <Navigate to="/dashboard" replace />;
  }

  // Research module and Compete Composer have been sunsetted
  if (pathname.startsWith('/research') || pathname.startsWith('/composer')) {
    return <Navigate to="/dashboard" replace />;
  }

  const hasAccessToInsight = isAdmin || isCurator || user?.isImpersonating;

  if (pathname.startsWith('/insights') && !hasAccessToInsight) {
    return <Navigate to="/dashboard" replace />;
  }

  if (pathname.startsWith('/review-insights')) {
    return <Navigate to="/insights" replace />;
  }

  // TODO (rhuan): this will be removed later, given that we will bring this page to v2
  if (pathname.startsWith('/winloss/survey')) {
    redirectToV1();
    return null;
  }

  if (
    !!matchPath(
      { path: WIN_LOSS_PUBLIC_URL_BASE, caseSensitive: true, end: false },
      pathname,
    )
  ) {
    return (
      <Routes>
        <Route
          path={`${WIN_LOSS_PUBLIC_URL_BASE}/*`}
          element={<PublicWinLossRouter />}
        />
      </Routes>
    );
  }

  return (
    <>
      <PersistGate persistor={persistor}>
        {() => (
          <React.Suspense fallback={<div />}>
            <ErrorBoundary>
              <Embeds />
              <Routes>
                <Route
                  path="/"
                  element={
                    <MainLayout>
                      <CardViewer />
                      <CardEditorModalContainer />
                      <Outlet />
                    </MainLayout>
                  }
                >
                  <Route
                    path="/"
                    element={<Navigate to={homeRoute} replace />}
                  />
                  <Route path="/dashboard" element={<DashboardView />} />
                  <Route path="alerts/*" element={<AlertsRouter />} />
                  {isKnowledgeHubEnabled && (
                    <Route
                      path="/knowledge-hub/*"
                      element={<KnowledgeHubRouter />}
                    />
                  )}
                  <Route path="/company/*" element={<CompanyRouter />} />
                  <Route path="/search/*" element={<SearchView />} />
                  <Route path="/profile/:id/*" element={<ProfileView />} />
                  <Route
                    path="/extension/guide/*"
                    element={<ExtensionGuideView />}
                  />
                  <Route
                    path="/integrations/*"
                    element={<IntegrationsRouter />}
                  />
                  <Route
                    path="/insights/*"
                    element={<ReviewInsightsRouter />}
                  />
                  {isCurator && (
                    <Route
                      path="/call-insights/*"
                      element={<CallInsightsRouter />}
                    />
                  )}
                  <Route path="/monitors/*" element={<MonitorsRouter />} />
                  <Route
                    path={`${WIN_LOSS_URL_BASE}/*`}
                    element={
                      isWinLossEnabled ? (
                        <WinLossRouter />
                      ) : (
                        <WinLossPromoRouter />
                      )
                    }
                  />
                  <Route path="/settings/*" element={<SettingsRouter />} />
                  <Route path="*" element={<PageNotFound />} />
                  {reportsRoute(<ReportsView />)}
                  <Route
                    path="/dynamic-board/*"
                    element={<DynamicBoardRouter />}
                  />
                </Route>
                <Route
                  path="/card/embed/:cardId"
                  element={<CardEmbedsView />}
                />
                <Route path="/feed" element={<RedirectToV1 />} />
              </Routes>
            </ErrorBoundary>
          </React.Suspense>
        )}
      </PersistGate>
    </>
  );
}

export const reportsRoute = (reportsView: ReactNode) => (
  <Route path="/reports/*" element={reportsView}>
    <Route path=":id" element={null} />
    <Route path=":id/:type" element={null} />
    <Route path=":id/:type/user/:modeUser" element={null} />
    <Route path=":id/:type/:drillDownReport" element={null} />
    <Route
      path="not-authorized"
      element={<PageNotAuthorized fullscreen={false} />}
    />
  </Route>
);

export default App;
