import type {
  GetTagsCountResponse,
  RequestError,
  TaggedContentDetails,
} from './api';
import type { RefObject } from 'react';

export type BIChart = {
  name: string;
  value?: number;
};

export type HighlightsFilterArgs = {
  topics?: string[];
  metadataValues?: string[];
  metadataKeys?: string[];
  programs?: string[];
  tags?: string[];
  dates?: string[];
  limit?: number;
  programId?: string;
  competitorId?: string;
};

export type TaggedContentResponse = {
  taggedContentIdColumn: string;
  taggedContentTitle: string;
  taggedContentText: string;
  taggedContentCreated: string;
  programFilters: Record<string, string>[];
  topicFilters: Record<string, string>[];
  tagFilters: Record<string, string>[];
  metadataKeys: Record<string, string>[];
  metadataValueFilters: Record<string, string>[];
  startDate: string;
  endDate: string;
}[];

export type HighlightItemProps = {
  id: string;
  text: string;
  name: string;
  programId: string;
  programType?: string;
  programName?: string;
  companyName?: string;
  companyLogoUrl?: string;
  contentId: string;
  interviewId: string;
  setCreateCardModalVisibility?: (isOpen: boolean) => void;
  slots: TaggedContentDetails[];
  opportunityId?: string;
};

export type HighlightItemsLayoutType = 'list' | 'columns';

export type SearchValue = string | number | boolean;
export type SearchParams = Record<
  string,
  SearchValue | SearchValue[] | undefined
>;

export type SelectedFilterRibbonObject = Record<string, string[]>;

export type MetadataKeyParsedNames = string[];

export type TextTransformValues =
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | 'full-width'
  | 'full-size-kana';

export type LayoutOutlet = {
  pageRef: RefObject<HTMLDivElement>;
  pageContainerRef: RefObject<HTMLDivElement>;
  showNewUI?: boolean;
};

export type BIReportsUrlParams = Record<string, string[]>;

export type BIReportsChartsFilters = {
  topics?: string[];
  limit?: number;
};

export type ChartData = {
  tags: Array<
    Omit<GetTagsCountResponse[number], 'count'> & {
      value: number;
    }
  >;
  error?: RequestError;
  isFetching: boolean;
};

export type PropsOf<T> = T extends React.ElementType<infer P> ? P : never;

export type Breakpoint = {
  min: number;
  max?: number;
};

export enum OpportunityTabsHashes {
  overview = 'overview',
  interviews = 'interviews',
  surveys = 'surveys',
  reports = 'reports',
}

export type OpportunityTabsHashesKeys = `${keyof typeof OpportunityTabsHashes}`;

export type FilterBarStaticQueryParamFilter<
  T extends 'boolean' | 'string' | 'list',
  V = T extends 'boolean'
    ? boolean
    : T extends 'string'
    ? string
    : T extends 'list'
    ? string
    : never,
> = {
  label: string;
  queryParam: string;
  onClick?: (value: V) => void;
  type: T;
  value?: V;
  defaultValue?: V;
};
