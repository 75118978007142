import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { fetchCardHistory } from 'api/endpoints/card';

import type { CardType, CardHistoryType } from 'api/api.types';
import type { RootState } from 'store/store.types';

const useCardHistory = (card?: CardType) => {
  const cardId = card?.id;
  const currentCard = useSelector<RootState, CardType | undefined>((state) =>
    state.cards.byId.get(String(cardId)),
  );
  const snapshotsCount = card?.snapshotsCount || 0;
  const page = useRef(1);
  const [history, setHistory] = useState<CardHistoryType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const hasMoreHistoryRef = useRef(false);

  const loadHistory = useCallback(
    async (currentHistory: CardHistoryType[] = []) => {
      if (!cardId || snapshotsCount === 0) {
        return;
      }

      try {
        setLoading(true);
        const { data } = await fetchCardHistory({
          path: {
            id: cardId,
          },
          query: {
            page: page.current,
          },
        });

        const { items, totalItems } = data;

        if (!items?.length) {
          return;
        }

        hasMoreHistoryRef.current =
          currentHistory.length + items.length < totalItems;
        page.current += 1;

        setHistory([
          { ...currentCard, versionId: 0 },
          ...currentHistory,
          ...items,
        ]);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [cardId, currentCard, snapshotsCount],
  );

  useEffect(() => {
    loadHistory();
  }, [loadHistory]);

  const loadMoreHistory = useCallback(() => {
    loadHistory(history.slice(1)); /// remove the current card from the history
  }, [loadHistory, history]);

  return useMemo(
    () => ({
      history,
      loading,
      error,
      hasMoreHistory: hasMoreHistoryRef.current,
      loadMoreHistory,
    }),
    [history, loading, error, loadMoreHistory],
  );
};

export default useCardHistory;
