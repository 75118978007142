/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { KlueEditorContent } from '@kluein/content-editor';
import {
  Box,
  Button,
  Modal,
  TextInput,
  Text,
  tokens,
  useResponsive,
  useNotifier,
  NotifierType,
  PhosphorIcon,
} from '@kluein/klue-ui';
import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
  type CSSProperties,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useAuth from 'contexts/auth/useAuth';
import TEST_IDS from 'test-ids';

import {
  areCardEditorPropsEqual,
  validateEditorContent,
} from './CardEditor.utils';
import CardEditorFailedLoading from './CardEditorFailedLoading';
import CardEditorLoading from './CardEditorLoading';
import CardHistory, { type HistoryItemSelectedType } from './CardHistory';
import { useCardEditor } from './hooks/useCardEditor';
import { useTextInputAutofocus } from './hooks/useTextInputAutofocus';
import { CardEditorCardInfo } from './partials/CardEditorInfo';
import CardEditorTags from './partials/CardEditorTags';
import { CardEditorToolbar } from './partials/CardEditorToolbar';

import { KlueCardVisibilityGroups } from '../klueCard/partials';

import type { CardType } from 'api/api.types';
import type { GetCardPermissionCardsDataResult } from 'pages/profile/partials/sideAction/bulkEdit/cardPermissions/CardPermissions.utils';
import type { RootState } from 'store/store.types';

export type CardEditorProps = {
  card?: CardType;
  profileId?: number;
  isLoading?: boolean;
  failedLoading?: boolean;
  onClose: (didSave?: boolean) => void;
};

const TEMP_PLACEHOLDER_TEXT = 'Create your content here';
const minModalContentHeight = '1000px';

const CardEditor: React.FC<CardEditorProps> = ({
  card,
  profileId,
  isLoading,
  failedLoading,
  onClose,
}) => {
  // need reference to model card in case history is restore. This ensures the
  // updated date is correct.
  const modelCard = useSelector<RootState, CardType | undefined>((state) =>
    state.cards.byId.get(String(card?.id || '')),
  );
  const { t } = useTranslation(['Card']);
  const { isCardTitleOptional } = useAuth();
  const { notify } = useNotifier();
  const [fullScreen, setFullScreen] = useState(false);
  const [versionHistoryChanged, setVersionHistoryChanged] = useState(false);
  const [showCardHistory, setShowCardHistory] = useState(false);
  const [nullifyHistoryDrawer, setNullifyHistoryDrawer] = useState(true);
  const [titleDirty, setTitleDirty] = useState(false);
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const { setTextInputRef } = useTextInputAutofocus({ card });
  const { snapshotsCount } = card || {};
  const { minWidth, maxWidth } = useResponsive();

  const width = !minWidth.small ? 'unset' : tokens.breakpoint.small;

  const handleContentRestore = useCallback(() => {
    notify({
      type: NotifierType.INFO,
      message: t('Card:editor.localRestore'),
      duration: 3,
    });
  }, [notify, t]);

  const {
    editor,
    title,
    updateTitle,
    saveCard,
    isSaving,
    isDraft,
    isDirty,
    allAccess,
    visibilityGroups,
    handleUpdateVisibilityGroups,
    cardPermissionsDirty,
    tags,
    addTag,
    removeTag,
    tagsDirty,
    clearBackup,
    changeContent,
  } = useCardEditor({
    initialCard: card,
    profileId,
    editorContainerRef,
    onContentRestore: handleContentRestore,
  });

  const revealCardHistory = useCallback(() => {
    setNullifyHistoryDrawer(false);
    setShowCardHistory(true);
  }, []);

  const hideCardHistory = useCallback(() => {
    setShowCardHistory(false);
    setTimeout(() => {
      setNullifyHistoryDrawer(true);
    }, 500);
  }, []);

  const handleToggleShowCardHistory = useCallback(() => {
    if (showCardHistory && versionHistoryChanged) {
      // we're closing history, so we need to restore the content since it was
      // not explicitly saved.
      changeContent(card, true);
      setVersionHistoryChanged(false);
    }
    if (showCardHistory) {
      hideCardHistory();
    } else {
      revealCardHistory();
    }
  }, [
    card,
    changeContent,
    showCardHistory,
    versionHistoryChanged,
    hideCardHistory,
    revealCardHistory,
  ]);

  useEffect(() => {
    if (maxWidth.large && showCardHistory) {
      handleToggleShowCardHistory();
    }
  }, [maxWidth.large, handleToggleShowCardHistory, showCardHistory]);

  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateTitle(event.target.value);
      setTitleDirty(true);
    },
    [updateTitle],
  );

  const handleTitleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event?.key === 'Enter' || event?.key === 'Tab') {
        event?.preventDefault();
        editor?.commands.focus('end');
      }
    },
    [editor],
  );

  const handleRestoreContent = useCallback(async () => {
    try {
      hideCardHistory();
      setVersionHistoryChanged(false);
      await saveCard();
    } catch (error) {
      notify({
        type: NotifierType.ERROR,
        message: t('Card:editor.history.restoreError'),
        icon: <PhosphorIcon.WarningCircle />,
      });
    }
  }, [notify, saveCard, t, hideCardHistory]);

  const handleSave = useCallback(async () => {
    if (!isCardTitleOptional && !title) {
      return notify({
        type: NotifierType.ERROR,
        message: t('Card:editor.validation.title.required'),
        icon: <PhosphorIcon.WarningCircle />,
      });
      return;
    }

    const editorContent = editor?.getJSON();

    if (editorContent) {
      const validationError = validateEditorContent(editorContent);
      if (validationError) {
        return notify({
          type: NotifierType.ERROR,
          message: t('Card:editor.validation.content.invalidContent', {
            error: validationError,
          }),
          icon: <PhosphorIcon.WarningCircle />,
        });
      }
    }

    const content = (editor?.getText() || '').trim();
    if (
      (!isDirty &&
        !titleDirty &&
        !cardPermissionsDirty &&
        !tagsDirty &&
        !versionHistoryChanged) ||
      content === TEMP_PLACEHOLDER_TEXT
    ) {
      return notify({
        type: NotifierType.ERROR,
        message: t('Card:editor.validation.content.required'),
        icon: <PhosphorIcon.WarningCircle />,
      });
    }

    try {
      await saveCard();

      notify({
        type: NotifierType.SUCCESS,
        message: t('Card:editor.modal.saveSuccess'),
        icon: <PhosphorIcon.Check />,
      });

      setTitleDirty(false);
      onClose(true);
    } catch (error) {
      notify({
        type: NotifierType.ERROR,
        message: t('Card:editor.modal.saveError'),
        icon: <PhosphorIcon.WarningCircle />,
      });
    }
  }, [
    isCardTitleOptional,
    title,
    editor,
    isDirty,
    titleDirty,
    cardPermissionsDirty,
    tagsDirty,
    notify,
    t,
    saveCard,
    onClose,
    versionHistoryChanged,
  ]);

  const handleSetFullScreen = useCallback(() => {
    setFullScreen(!fullScreen);
  }, [fullScreen]);

  const handleModalClose = useCallback(
    (didSave?: boolean) => {
      clearBackup();
      onClose(didSave);
    },
    [onClose, clearBackup],
  );

  const cancelButton = (
    <Button
      variant="flat-form"
      label={t('Card:editor.modal.cancel')}
      onClick={() => handleModalClose()}
      margin={{ right: 'small' }}
    />
  );

  const saveButtonLabel = isSaving
    ? t('Card:editor.modal.saving')
    : t('Card:editor.modal.save');

  const saveButton = (
    <Button
      a11yTitle={saveButtonLabel}
      variant={versionHistoryChanged ? 'danger' : 'highlight-form'}
      label={saveButtonLabel}
      onClick={handleSave}
      disabled={
        !versionHistoryChanged &&
        (isSaving ||
          (!isDirty && !titleDirty && !cardPermissionsDirty && !tagsDirty))
      }
      style={{ borderRadius: tokens.borderRadius.large }}
      data-test-id={TEST_IDS.cardEditor.saveButton}
    />
  );

  const restoreButton = (
    <Button
      a11yTitle={t('Card:editor.history.restore')}
      variant="highlight-form"
      label={t('Card:editor.history.restore')}
      onClick={handleRestoreContent}
      disabled={!versionHistoryChanged || isSaving}
      data-test-id={TEST_IDS.cardEditor.restoreButton}
    />
  );

  const loadingComp =
    isLoading && !failedLoading ? <CardEditorLoading /> : null;
  const failedComp = failedLoading ? (
    <CardEditorFailedLoading onClose={handleModalClose} />
  ) : null;

  const handleOnVisibilityGroupSelected = useCallback(
    (cardPermissionCardsData?: GetCardPermissionCardsDataResult) =>
      handleUpdateVisibilityGroups(card, cardPermissionCardsData),
    [handleUpdateVisibilityGroups, card],
  );

  const handleCardHistorySelected = useCallback(
    ({ history }: HistoryItemSelectedType) => {
      changeContent(history, history?.versionId === 0);
      setVersionHistoryChanged(history?.versionId !== 0);
    },
    [changeContent],
  );

  const cardHistoryStyle: CSSProperties = showCardHistory
    ? {
        pointerEvents: 'none',
        opacity: 0.37,
      }
    : {
        pointerEvents: 'auto',
        opacity: 1,
      };

  const drawerContent = useMemo(() => {
    if (snapshotsCount && !nullifyHistoryDrawer) {
      return (
        <CardHistory
          card={card}
          onCardHistorySelected={handleCardHistorySelected}
          onCloseCardHistory={handleToggleShowCardHistory}
        />
      );
    }
    return null;
  }, [
    card,
    handleCardHistorySelected,
    handleToggleShowCardHistory,
    snapshotsCount,
    nullifyHistoryDrawer,
  ]);

  return (
    <>
      <Modal
        showCloseButton={true}
        width={fullScreen ? 'auto' : width}
        full={fullScreen}
        responsive={!fullScreen}
        background={tokens.color.neutral.white.main}
        drawerContent={drawerContent}
        showDrawer={showCardHistory}
      >
        <Box
          flex={false}
          fill="horizontal"
          direction="row"
          border={{
            side: 'bottom',
            color: tokens.color.neutral.lightgrey.main,
          }}
          align="center"
          pad={{
            horizontal: tokens.spacing.medium,
            vertical: tokens.spacing.small,
          }}
          background={tokens.color.neutral.white.main}
        >
          <PhosphorIcon.PencilSimple
            size={tokens.iconSize.medium}
            weight="regular"
          />
          <TextInput
            ref={setTextInputRef}
            size="large"
            a11yTitle={t('Card:editor.title.placeholder')}
            onChange={handleTitleChange}
            value={title}
            placeholder={t('Card:editor.title.placeholder')}
            style={{
              fontSize: tokens.fontSize.bodyFont.xlarge,
              fontWeight: tokens.fontWeight.semibold,
              border: 'none',
              ...cardHistoryStyle,
            }}
            data-test-id={TEST_IDS.cardEditor.titleInput}
            onKeyDown={handleTitleKeyDown}
          />
          <Box onClick={() => handleModalClose()} margin={{ left: 'small' }}>
            <PhosphorIcon.X
              size={tokens.iconSize.medium}
              style={{ cursor: 'pointer' }}
              weight="regular"
            />
          </Box>
        </Box>
        <Box
          flex={false}
          direction="row"
          align="center"
          style={{
            position: 'relative',
            zIndex: 9,
          }}
          pad={{
            horizontal: tokens.spacing.small,
            top: tokens.spacing.medium,
            bottom: tokens.spacing.xlarge,
          }}
          background={tokens.color.neutral.white.main}
        >
          {!failedLoading && !isLoading && editor && (
            <>
              <Box style={cardHistoryStyle}>
                <CardEditorToolbar
                  editor={editor}
                  cardId={card?.id}
                  profileId={card?.board?.profileId}
                />
              </Box>
              <Box
                direction="row"
                gap="xsmall"
                justify="end"
                height="xsmall"
                align="center"
                flex={{ shrink: 0, grow: 1 }}
              >
                <Button
                  variant="plain"
                  size="small"
                  focusIndicator={false}
                  onClick={handleSetFullScreen}
                  // Temporary disable fullscreen button
                  style={{ display: 'none' }}
                >
                  <PhosphorIcon.CornersOut />
                </Button>
                {!maxWidth.large && (
                  <Button
                    variant="plain"
                    focusIndicator={false}
                    disabled={!snapshotsCount}
                    margin={{ right: 'xxsmall' }}
                    onClick={handleToggleShowCardHistory}
                  >
                    <PhosphorIcon.ClockCounterClockwise
                      size={tokens.iconSize.medium}
                      weight="regular"
                    />
                  </Button>
                )}
              </Box>
            </>
          )}
        </Box>

        <Modal.Content
          background={tokens.color.neutral.white.main}
          alignContent={fullScreen ? 'center' : undefined}
          wrap={true}
          overflow="hidden"
          pad="none"
          height={minModalContentHeight}
        >
          {!failedLoading && !isLoading ? (
            <Box
              ref={editorContainerRef}
              suppressContentEditableWarning={true}
              overflow={{ vertical: 'scroll' }}
              background={tokens.color.neutral.white.main}
              pad={{
                horizontal: `calc(${tokens.spacing.xxlarge} + ${tokens.spacing.medium})`,
                vertical: tokens.spacing.medium,
              }}
              className="klue-content-service-editor"
            >
              <Box
                flex={{ shrink: 0 }}
                style={showCardHistory ? { pointerEvents: 'none' } : {}}
              >
                <KlueEditorContent editor={editor} topbar={false} />
                <Box
                  margin={{ vertical: tokens.spacing.medium }}
                  flex={{ shrink: 0 }}
                  style={cardHistoryStyle}
                >
                  <CardEditorTags
                    tags={tags}
                    onAddTag={addTag}
                    onRemoveTag={removeTag}
                  />
                </Box>
              </Box>
            </Box>
          ) : null}
          {loadingComp}
          {failedComp}
        </Modal.Content>
        {!failedLoading && !isLoading && !showCardHistory ? (
          <CardEditorCardInfo card={modelCard} /> // model card has updated date info based on restore.
        ) : null}
        {!failedLoading ? (
          <Box
            direction="row"
            flex={{ shrink: 0 }}
            pad={{ horizontal: 'small' }}
            justify="end"
            border={{
              side: 'top',
              color: tokens.color.neutral.lightgrey.main,
            }}
            background={tokens.color.neutral.white.main}
          >
            {isLoading ? (
              <Box margin={{ bottom: tokens.spacing.small }}>
                {cancelButton}
              </Box>
            ) : showCardHistory ? (
              <Box
                direction="row"
                align="center"
                justify="between"
                margin={{ vertical: tokens.spacing.small }}
                flex={true}
              >
                <Box
                  direction="row"
                  gap="small"
                  align="center"
                  onClick={handleToggleShowCardHistory}
                >
                  <PhosphorIcon.ArrowLeft
                    size={tokens.iconSize.medium}
                    color={tokens.color.primary[1000]}
                  />
                  <Text color={tokens.color.primary[1000]}>
                    {t('Card:editor.history.back')}
                  </Text>
                </Box>
                {restoreButton}
              </Box>
            ) : (
              <KlueCardVisibilityGroups
                cardId={card?.id}
                isDraft={isDraft}
                flex="grow"
                visibilityGroups={visibilityGroups}
                allAccess={allAccess}
                zIndex={10}
                onVisibilityGroupSelected={handleOnVisibilityGroupSelected}
              >
                {cancelButton}
                {saveButton}
              </KlueCardVisibilityGroups>
            )}
          </Box>
        ) : null}
      </Modal>
    </>
  );
};

export default React.memo(CardEditor, areCardEditorPropsEqual);
