import { setEndpoint } from '../configure';

import type { FetchCardByIdQueryType } from './card.types';
import type { UpdateCardsType, DeleteCardsType } from '../api.types';

export const [fetchCardById] = setEndpoint<
  { id: number },
  any,
  FetchCardByIdQueryType,
  any
>({
  path: '/cards/:id.json',
  id: 'fetchCardById',
  method: 'GET',
  namespace: 'cards',
});

export const [fetchCardSources] = setEndpoint<
  any,
  { id: number },
  { v: string },
  any
>({
  path: '/cards/:id/sources',
  id: 'fetchCardSources',
  method: 'GET',
  namespace: 'cards',
});

export const [addCardSource] = setEndpoint<
  { id: number },
  { url: string },
  any,
  any
>({
  path: '/cards/:id/sources',
  id: 'addCardSource',
  method: 'POST',
  namespace: 'cards',
});

export const [deleteCardSource] = setEndpoint<{ id: number }, any, any, any>({
  path: '/sources/:id',
  id: 'deleteCardSource',
  method: 'DELETE',
  namespace: 'cards',
});

export const [updateCards] = setEndpoint<
  any,
  Partial<UpdateCardsType>,
  any,
  any
>({
  path: `/cards.json?v=2`,
  id: 'updateCards',
  method: 'PUT',
  namespace: 'cards',
  globals: {
    contentType: 'application/json',
  },
});

export const [deleteCards] = setEndpoint<any, DeleteCardsType, any, any>({
  path: `/cards.json?v=2`,
  id: 'deleteCards',
  method: 'DELETE',
  namespace: 'cards',
  globals: {
    contentType: 'application/json',
  },
});

export const [fetchCardHistory] = setEndpoint<any, { id: number }, any, any>({
  path: '/cards/:id/snapshots',
  id: 'fetchCardHistory',
  method: 'GET',
  namespace: 'cards',
});
