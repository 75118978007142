import { Box, tokens } from '@kluein/klue-ui';
import { Suspense } from 'react';

import { AppNavBar } from 'components/layout/app-nav-bar';
import { AppSideAction } from 'components/layout/app-side-action';
import { PreviewBanner } from 'components/layout/preview-banner';
import { SubmitIntel } from 'components/layout/submit-intel';
import TEST_IDS from 'test-ids';

type MainLayoutPropTypes = {
  children: React.ReactChild | React.ReactNode[];
};

export default function MainLayout({ children }: MainLayoutPropTypes) {
  return (
    <Box fill>
      <PreviewBanner />
      <AppNavBar />
      <Box
        direction="row"
        fill
        overflow="hidden"
        data-test-id={TEST_IDS.layout.mainLayout.container}
        style={{ isolation: 'isolate' }}
      >
        <SubmitIntel />
        <Box
          background={tokens.color.neutral.rosegrey.main}
          fill
          data-test-id={TEST_IDS.layout.mainLayout.content}
          role="main"
        >
          <Suspense fallback={<div />}>{children}</Suspense>
        </Box>
        <AppSideAction />
      </Box>
    </Box>
  );
}
