import {
  Box,
  Button,
  TextInput,
  tokens,
  PhosphorIcon,
  Text,
} from '@kluein/klue-ui';
import { useState, useRef, useCallback } from 'react';

type AddItemTextInputProps = {
  existingItemNames: string[];
  placeholder: string;
  disabled: boolean;
  addNewButtonText: string;
  duplicateErrorMessage?: string;
  emptyErrorMessage?: string;
  onAddItem: (name: string) => void;
};

const AddItemTextInput = ({
  existingItemNames,
  placeholder,
  disabled,
  addNewButtonText,
  duplicateErrorMessage,
  emptyErrorMessage,
  onAddItem,
}: AddItemTextInputProps) => {
  const textInputRef = useRef<HTMLInputElement>(null);
  const [newItemName, setNewItemName] = useState('');
  const [newItemNameStatus, setNewItemNameStatus] = useState('');

  const handleAddItem = () => {
    const trimmedName = newItemName?.trim();
    const trimmedLowerCaseName = trimmedName?.toLowerCase();

    if (!trimmedLowerCaseName) {
      return setNewItemNameStatus(emptyErrorMessage ?? '');
    }

    if (
      duplicateErrorMessage &&
      existingItemNames.some(
        (name) => name?.toLowerCase() === trimmedLowerCaseName,
      )
    ) {
      return setNewItemNameStatus(duplicateErrorMessage);
    }

    setNewItemName('');
    textInputRef.current?.focus();

    onAddItem(trimmedName);
  };

  const handleAddItemSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault && e.preventDefault();
    handleAddItem();
  };

  const handleNewItemNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewItemName(e.target.value);
      setNewItemNameStatus('');
    },
    [],
  );

  return (
    <Box flex={{ shrink: 0 }}>
      <Box direction="row" gap="small" align="center" flex={{ shrink: 0 }}>
        <form onSubmit={handleAddItemSubmit} style={{ width: '100%' }}>
          <Box direction="row" gap="small" align="center">
            <TextInput
              value={newItemName}
              ref={textInputRef}
              size="medium"
              onChange={handleNewItemNameChange}
              placeholder={placeholder}
            />
            <Box flex={{ grow: 1 }}>
              <Button
                variant="primary"
                leftSlot={<PhosphorIcon.PlusCircle />}
                onClick={handleAddItem}
                disabled={
                  disabled ||
                  !newItemName ||
                  newItemName.trim().length === 0 ||
                  newItemNameStatus.length > 0
                }
              >
                {addNewButtonText}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
      {newItemNameStatus && (
        <Text size="small" color={tokens.color.status.error.main}>
          {newItemNameStatus}
        </Text>
      )}
    </Box>
  );
};

export default AddItemTextInput;
