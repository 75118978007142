import {
  tokens,
  PhosphorIcon,
  Box,
  Heading,
  Button,
  Text,
  Modal,
  useNotifier,
  NotifierType,
} from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import AddItemTextInput from 'components/common/profile-board-action-menu/AddItemTextInput';

import CardBattlecardItem from './CardBattlecardItem';

import { useCardBattlecards } from '../../hooks/use-card-battlecards';

import type { BattlecardsType } from 'api/api.types';

type KlueCardAssignToBattlcardModalProps = {
  id: number;
  profileId?: number;
  onClose: () => void;
};

const KlueCardAssignToBattlcardModal = ({
  id,
  profileId,
  onClose,
}: KlueCardAssignToBattlcardModalProps) => {
  const { t } = useTranslation();
  const { notify } = useNotifier();
  const {
    battlecards,
    checked,
    toggleChecked,
    counts,
    done,
    finishing,
    handleAddNewBattlecard: onAddNewBattlecard,
    addingNewBattlecard,
    dirty,
  } = useCardBattlecards({
    id,
    profileId,
  });
  const handleOnClose = () => {
    onClose();
  };

  const handleOnDone = async () => {
    try {
      await done();

      if (dirty) {
        notify({
          type: NotifierType.SUCCESS,
          message: t('Card:actions.assignToBattlecards.success'),
          icon: <PhosphorIcon.Check />,
        });
      }
      onClose();
    } catch (error) {
      notify({
        type: NotifierType.ERROR,
        message: t('Card:actions.assignToBattlecards.error'),
        icon: <PhosphorIcon.WarningCircle />,
      });
    }
  };

  const handleAddNewBattlecard = async (name: string) => {
    try {
      const battlecard = await onAddNewBattlecard(name);
      setTimeout(() => {
        const newBattlecardId = battlecard?.id;
        const lastLaneElement = document.getElementById(
          `card-battlecard-item-${newBattlecardId}`,
        );
        lastLaneElement?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 200);
    } catch (error) {
      notify({
        type: NotifierType.ERROR,
        message: t('Card:actions.assignToBattlecards.createError'),
        icon: <PhosphorIcon.WarningCircle />,
      });
    }
  };

  return (
    <Modal
      showCloseButton={false}
      width="xxxlarge"
      onClose={handleOnClose}
      background={tokens.color.neutral.white.main}
      responsive
    >
      <Modal.Header background={tokens.color.primary.main}>
        <Box direction="row" gap="small" align="center">
          <PhosphorIcon.Star />
          <Heading
            size="medium"
            margin="none"
            level="2"
            color={tokens.color.primary.contrastmain}
          >
            {t('Card:header.menu.assignToBattlecards')}
          </Heading>
        </Box>
        <Button
          variant="plain"
          onClick={handleOnClose}
          icon={<PhosphorIcon.X color={tokens.color.primary.contrastmain} />}
        />
      </Modal.Header>
      <Modal.Content>
        <Box
          flex={false}
          style={
            finishing || addingNewBattlecard
              ? { pointerEvents: 'none', opacity: 0.37 }
              : {}
          }
        >
          <Text fontWeight="semibold">Add to new Battlecard</Text>
          <Box margin={{ top: 'xxsmall', bottom: 'medium' }}>
            <AddItemTextInput
              existingItemNames={[]}
              addNewButtonText={t(
                'Card:actions.assignToBattlecards.newBattlecard',
              )}
              placeholder={t(
                'Card:actions.assignToBattlecards.newBattlecardPlaceholder',
              )}
              disabled={false}
              onAddItem={handleAddNewBattlecard}
            />
          </Box>
          <Box overflow="auto">
            {battlecards.map(
              (
                battlecard: Partial<BattlecardsType> & { id: number },
                index,
              ) => (
                <CardBattlecardItem
                  key={battlecard.id}
                  battlecard={battlecard}
                  checked={checked.get(battlecard.id) ?? false}
                  onToggleChecked={() => toggleChecked(battlecard.id)}
                  isDefault={index === 0}
                  cardCounts={counts.get(battlecard.id) ?? 0}
                />
              ),
            )}
            {!battlecards.length && (
              <Text size="small" color={tokens.color.primitives.grey[800]}>
                {t('Battlecard:manage.order.noBattlecards')}
              </Text>
            )}
          </Box>
        </Box>
        <Box
          direction="row"
          justify="end"
          margin={{ vertical: 'medium' }}
          gap="small"
        >
          <Button
            variant="flat-outlined"
            onClick={handleOnDone}
            disabled={addingNewBattlecard || finishing}
            label={
              // eslint-disable-next-line no-nested-ternary
              finishing
                ? t('Common:actions.saving')
                : addingNewBattlecard
                ? t('Common:actions.creatingBattlecard')
                : t('Common:done')
            }
          />
        </Box>
      </Modal.Content>
    </Modal>
  );
};

export default KlueCardAssignToBattlcardModal;
