import { useClickListener } from '@kluein/klue-ui';
import { createRef, useCallback } from 'react';

import { getCardIdFromTarget } from 'lib/utils';

type onClickParamType = (id: string) => void;
const EDITOR_CLASS_NAME = '.klue-content-service-editor';

export default function useCardClick(onClick: onClickParamType) {
  const containerRef = createRef<HTMLDivElement>();

  const handleCardLinks = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLAnchorElement;
      const id = getCardIdFromTarget(target);

      const editorElement = target.closest(EDITOR_CLASS_NAME);
      const isFromActiveEditor =
        editorElement !== null &&
        editorElement.getAttribute('contenteditable') === 'true';

      if (isFromActiveEditor) {
        return;
      }

      if (id) {
        e.stopPropagation();
        e.preventDefault();

        onClick(id.toString());
      }
    },
    [onClick],
  );

  useClickListener(null, [{ selector: 'a', onClick: handleCardLinks }]);

  return {
    ref: containerRef,
  };
}
