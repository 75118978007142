import {
  Box,
  List,
  Text,
  PhosphorIcon,
  type ListItemType,
  tokens,
  Spinner,
  Button,
} from '@kluein/klue-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CardHistoryItem from './CardHistoryItem';
import useCardHistory from './hooks/useCardHistory';

import type { CardType, CardHistoryType } from 'api/api.types';

export type HistoryItemSelectedType = { history: CardHistoryType };

type CardHistoryProps = {
  card?: CardType;
  onCardHistorySelected: (itemSelected: HistoryItemSelectedType) => void;
  onCloseCardHistory: () => void;
};

const getHistoryListData = (history: CardHistoryType[]) => {
  return history.map((item: CardHistoryType) => ({
    key: item.versionId.toString(),
    label: item.displayName,
  }));
};

const cardHistoryWidth = '300px';

const CardHistory = ({
  card,
  onCardHistorySelected,
  onCloseCardHistory,
}: CardHistoryProps) => {
  const {
    history,
    hasMoreHistory,
    loadMoreHistory,
    loading: loadingHistory,
  } = useCardHistory(card);
  const { snapshotsCount = 0 } = card || {};
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<string | undefined>('0');
  const [historyListData, setHistoryListData] = useState<ListItemType[]>([
    { key: '0', label: '' },
  ]);

  useEffect(() => {
    setHistoryListData(getHistoryListData(history || []));
  }, [history]);

  const handleCloseCardHistory = () => {
    onCloseCardHistory();
    setSelectedItem('0');
  };

  const handleCardHistorySelected = (versionId: number) => {
    const index = history.findIndex((h) => h.versionId === versionId);
    if (index === -1) {
      return;
    }

    const historyItem = history[index];
    setSelectedItem(versionId.toString());
    onCardHistorySelected({
      history: historyItem,
    });
  };

  if (!history?.length && !snapshotsCount) {
    return null;
  }

  return (
    <Box
      background={tokens.color.neutral.white.main}
      flex="grow"
      pad={{ horizontal: 'medium', bottom: 'medium' }}
      width={cardHistoryWidth}
    >
      <Box pad={{ vertical: 'large' }} direction="row" justify="between">
        <Text size="large" fontWeight="semibold">
          {t('Card:editor.history.header')}
        </Text>
        <Box onClick={handleCloseCardHistory} margin={{ left: 'small' }}>
          <PhosphorIcon.ArrowCircleLeft
            size={tokens.iconSize.medium}
            weight="regular"
          />
        </Box>
      </Box>
      <List
        data={historyListData}
        a11yTitle={t('Card:editor.history.header')}
        itemGap="small"
        itemProps={{ variant: 'flat-outlined' }}
        selectedItem={selectedItem}
        renderItem={(item) => {
          const historyItem = history.find(
            (history) => history.versionId === +item.key,
          );
          if (!historyItem) {
            return <Spinner />;
          }

          return (
            <CardHistoryItem
              cardHistoryItem={historyItem}
              selected={selectedItem === item.key}
              active={item.key === '0'}
              onSelectHistory={handleCardHistorySelected}
            />
          );
        }}
      />
      {loadingHistory && (
        <Box pad={{ vertical: 'medium' }} align="center">
          <Spinner />
        </Box>
      )}
      {!loadingHistory && hasMoreHistory && (
        <Box pad={{ vertical: 'medium' }} align="center">
          <Button
            variant="flat-outlined"
            size="small"
            onClick={loadMoreHistory}
            label={t('Card:editor.history.showMore')}
          />
        </Box>
      )}
    </Box>
  );
};

export default CardHistory;
