import { Box, Text, tokens, Image, Badge } from '@kluein/klue-ui';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { formatName } from 'lib/utils';
import TEST_IDS from 'test-ids';

import type { UserType, CardHistoryType } from 'api/api.types';
import type { RootState } from 'store/store.types';

const Container = styled(Box)`
  &:hover {
    border: 1px solid ${tokens.color.secondary.main};
    background: ${tokens.color.secondary[100]};
  }
`;

const CardHistoryItem = ({
  cardHistoryItem,
  selected = false,
  active = false,
  onSelectHistory,
}: {
  cardHistoryItem: CardHistoryType;
  selected: boolean;
  active: boolean;
  onSelectHistory: (versionId: number) => void;
}) => {
  const { t } = useTranslation();
  const authorIdString = String(cardHistoryItem.author?.id || '');
  const updatedAt = cardHistoryItem.updatedAt;
  const author = useSelector<RootState, UserType | undefined>((state) =>
    state.users.byId.get(authorIdString),
  );
  const updateDateFormated = format(
    new Date(updatedAt),
    'LLL dd, yyyy - p',
  ).toLocaleUpperCase();

  const { name, image } = author || {};

  const dispatch = useDispatch();

  useEffect(() => {
    if (cardHistoryItem.author?.id) {
      dispatch.users.loadOrFetchUserById(String(cardHistoryItem.author.id));
    }
  }, [dispatch, cardHistoryItem.author?.id]);

  return (
    <Container
      border={{ color: tokens.color.neutral[500] }}
      round="small"
      pad="small"
      background={
        selected ? tokens.color.highlight.main : tokens.color.neutral[100]
      }
      onClick={() => onSelectHistory(cardHistoryItem.versionId)}
      data-testid={TEST_IDS.editor.cardHistory}
    >
      <Box direction="row" gap="small" align="start">
        {image && (
          <Box
            border={{
              color: tokens.color.neutral.black.main,
              side: 'all',
              size: 'small',
            }}
            overflow="hidden"
            round="full"
            width="xxsmall"
            height="xxsmall"
            flex={false}
          >
            <Image alt={name} src={image} fit="contain" />
          </Box>
        )}
        <Box gap="xxsmall">
          <Text>{formatName(name || '')}</Text>
          <Text fontWeight="semibold">{updateDateFormated}</Text>
        </Box>
        {active ? (
          <Badge label={t('Card:editor.history.active')} color="green" />
        ) : null}
      </Box>
    </Container>
  );
};

export default CardHistoryItem;
