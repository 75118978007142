import { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAuth } from 'contexts/auth';
import store from 'store';
import { useUpdateBattlecardMutation } from 'store/api/battlecards';
import { useCreateBattlecardMutation } from 'store/api/battlecards/battlecards.api';

import type { BattlecardLayout, BattlecardsType } from 'api/api.types';
import type { Dispatch } from 'store/store.types';

type CardBattlecardsProps = {
  id: number;
  profileId?: number;
};

const getCheckedMapFromBattlecards = (
  battlecards: (Partial<BattlecardsType> & { id: number })[],
  id: number,
) => {
  const checkedMap = new Map();
  battlecards.forEach((battlecard) => {
    checkedMap.set(battlecard.id, battlecard.cards?.desktop.includes(id));
  });
  return checkedMap;
};

const getCountsMapFromBattlecards = (
  battlecards: (Partial<BattlecardsType> & { id: number })[],
) => {
  const countsMap = new Map();
  battlecards.forEach((battlecard) => {
    countsMap.set(battlecard.id, battlecard.cards?.desktop.length ?? 0);
  });
  return countsMap;
};

const bcSort = (a: BattlecardsType, b: BattlecardsType) => {
  return parseFloat(a.viewOrder) - parseFloat(b.viewOrder);
};

const useCardBattlecards = ({ id, profileId }: CardBattlecardsProps) => {
  const { company } = useAuth();
  const [updateBattlecard] = useUpdateBattlecardMutation();
  const [createBattlecard] = useCreateBattlecardMutation();
  const dispatch = useDispatch<Dispatch>();

  const {
    profiles: { fetchProfileById },
  } = dispatch;
  const { currentBattlecards } = useSelector(
    store.select(({ battlecards }) => ({
      currentBattlecards: battlecards.currentBattlecards,
    })),
  );
  const [battlecards, setBattlecards] = useState<
    (Partial<BattlecardsType> & { id: number })[]
  >(currentBattlecards?.sort(bcSort));
  const [finishing, setFinishing] = useState(false);
  const [addingNewBattlecard, setAddingNewBattlecard] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [originalChecked, setOriginalChecked] =
    useState<Map<number, boolean>>();
  const [checked, setChecked] = useState<Map<number, boolean>>(() => {
    const checkMap = getCheckedMapFromBattlecards(battlecards, id);
    setOriginalChecked(checkMap);
    return checkMap;
  });
  const [counts, setCounts] = useState<Map<number, number>>(
    getCountsMapFromBattlecards(battlecards),
  );

  useEffect(() => {
    const sortedBattlecards = currentBattlecards?.sort(bcSort);
    setBattlecards(sortedBattlecards);
    const checkMap = getCheckedMapFromBattlecards(sortedBattlecards, id);
    setChecked(checkMap);
    setOriginalChecked(checkMap);
    setCounts(getCountsMapFromBattlecards(sortedBattlecards));
  }, [currentBattlecards, id]);

  const handleSaveToggledBattlecards = useCallback(async () => {
    if (!profileId) {
      return;
    }

    const toggledBattlecards = battlecards.reduce((acc, battlecard) => {
      const checkedValue = checked.get(battlecard.id);
      const originalCheckedValue = originalChecked?.get(battlecard.id);

      // don't update if the value hasn't changed
      if (checkedValue === originalCheckedValue) {
        return acc;
      }

      const cards = battlecard.cards?.desktop ?? [];
      if (checkedValue) {
        cards.push(id);
      } else {
        const index = cards.indexOf(id);
        if (index > -1) {
          cards.splice(index, 1);
        }
      }

      acc[battlecard.id] = cards;

      return acc;
    }, {} as Record<number, number[]>);

    try {
      const promises: Promise<BattlecardsType>[] = [];
      Object.entries(toggledBattlecards).forEach(([battlecardId, cards]) => {
        promises.push(
          updateBattlecard({
            id: parseInt(battlecardId),
            cardData: { desktop: cards },
          })?.unwrap(),
        );
      });

      const results = await Promise.allSettled(promises);
      let errorCount = 0;

      results.forEach((promise, index) => {
        if (promise.status === 'fulfilled') {
          const result = promise.value;
          if (result && typeof result === 'object') {
            const updatedBattlecard = { ...result, profile: profileId };
            dispatch.battlecards.update(updatedBattlecard);
          }
        } else {
          errorCount++;
        }
      });
      if (errorCount) {
        return Promise.reject(new Error('Failed to save battlecards'));
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }, [
    battlecards,
    checked,
    dispatch.battlecards,
    id,
    originalChecked,
    profileId,
    updateBattlecard,
  ]);

  const toggleChecked = useCallback(
    (battlecardId: number) => {
      const currentlyChecked = checked.get(battlecardId);
      const battlecard = battlecards.find(({ id }) => id === battlecardId);
      if (!battlecard) {
        return;
      }

      const cards =
        battlecard.cards?.desktop?.filter((id) => id).map((id) => id) ?? [];
      if (!currentlyChecked) {
        cards.push(id);
      } else {
        const index = cards.indexOf(id);
        if (index > -1) {
          cards.splice(index, 1);
        }
      }

      setChecked((prev) => {
        const newChecked = new Map(prev);
        newChecked.set(battlecardId, !currentlyChecked);
        return newChecked;
      });

      setCounts((prevCounts) => {
        const currentCount = prevCounts.get(battlecardId) ?? 0;
        const newCount = currentlyChecked ? currentCount - 1 : currentCount + 1;
        const newCounts = new Map(prevCounts);
        newCounts.set(battlecardId, newCount);
        return newCounts;
      });
      setDirty(true);
    },
    [checked, setChecked, setCounts, setDirty, battlecards, id],
  );

  const handleAddNewBattlecard = useCallback(
    async (name: string) => {
      try {
        setAddingNewBattlecard(true);
        await handleSaveToggledBattlecards();
        const layout: BattlecardLayout = !company?.companyData
          ?.stackerIsDefaultBattlecardLayoutDisabled
          ? 'stacker'
          : 'single';
        const payload = {
          title: name,
          layout,
          profileId,
          cardData: { desktop: [id] },
        };
        const newBattlecard = await createBattlecard(payload).unwrap();
        await dispatch.profiles.refreshProfileAndRivalCounts({
          profileId,
        });
        setDirty(true);
        return newBattlecard;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        setAddingNewBattlecard(false);
      }
    },
    [
      company,
      createBattlecard,
      dispatch,
      id,
      profileId,
      handleSaveToggledBattlecards,
    ],
  );

  const handleDone = useCallback(async () => {
    if (!profileId || !dirty) {
      return;
    }

    setFinishing(true);

    try {
      await handleSaveToggledBattlecards();
      await fetchProfileById(profileId);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setFinishing(false);
    }
  }, [fetchProfileById, profileId, handleSaveToggledBattlecards, dirty]);

  return useMemo(
    () => ({
      battlecards,
      checked,
      toggleChecked,
      counts,
      done: handleDone,
      handleAddNewBattlecard,
      finishing,
      addingNewBattlecard,
      dirty,
    }),
    [
      battlecards,
      checked,
      toggleChecked,
      counts,
      handleDone,
      handleAddNewBattlecard,
      finishing,
      addingNewBattlecard,
      dirty,
    ],
  );
};

export default useCardBattlecards;
